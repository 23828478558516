import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import path_img from "../assets/images/studydetail/path-1179@1x.png"
import { useQuery } from "@apollo/client"
import { COOKIE_POLICY_QUERY } from "../components/graphql"
import { Link } from "gatsby"
import ReactMarkdown from "react-markdown"

const CookiePolicy = ({ data }) => {
  const cookiePolicyData = useQuery(COOKIE_POLICY_QUERY)
  const { allStrapiCookiePolicy, allStrapiHomePage } = data
  const seoTitle =
    allStrapiCookiePolicy !== null
      ? allStrapiCookiePolicy.edges[0].node.title
      : ""
  const seoMetaDescription =
    allStrapiCookiePolicy !== null
      ? allStrapiCookiePolicy.edges[0].node.metaDescription
      : ""
  const seoImage =
    allStrapiHomePage !== null
      ? allStrapiHomePage.edges[0].node.metaImage
        ? allStrapiHomePage.edges[0].node.metaImage.url
        : ""
      : ""

  React.useEffect(() => {
    if (typeof window !== `undefined`) {
      if (typeof document !== `undefined`) {
      localStorage.setItem('lastRevistedPage',window.location.pathname)
      }
    }
  });
  return (
    <>
      <SEO title={seoTitle} description={seoMetaDescription} image={seoImage} />
      <Layout>
        {cookiePolicyData.data ? (
          <>
            <main class="contactus">
              <div class="main-content">
                <div class="container">
                  <div class="breadcrumbs">
                    <div class="smalltext">
                      <img src={path_img} alt="" />
                      <div className="last-text">
                        / {cookiePolicyData.data.cookiePolicy.title}
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12 cms_page">
                      <div class="content-area">
                        <div class="title">
                          {cookiePolicyData.data.cookiePolicy.title
                            ? cookiePolicyData.data.cookiePolicy.title
                            : ""}
                        </div>
                        <div class="content">
                          {cookiePolicyData.data.cookiePolicy.description ? (
                            <ReactMarkdown
                              source={
                                cookiePolicyData.data.cookiePolicy.description
                              }
                            />
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </>
        ) : null}
      </Layout>
    </>
  )
}
export default CookiePolicy

export const query = graphql`
  query {
    allStrapiCookiePolicy {
      edges {
        node {
          title
          metaDescription
        }
      }
    }
    allStrapiHomePage {
      edges {
        node {
          metaImage {
            url
          }
        }
      }
    }
  }
`
